<template>
  <GfrButton :class="['com-download-btn', className, `com-download-${platform}`]" @click="handlePopup">
    <slot />
    <svg class="com-download-icon" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 6.99259L9.5 12.5L15 7L11 6.99259V0H8V7L4 6.99259Z" fill="black" />
      <path d="M2 14.0489V7L0 6.99259V16H19V7H17V14L2 14.0489Z" fill="black" />
    </svg>
  </GfrButton>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'
type DownloadPlatform = 'all' | 'desktop' | 'mobile'
const { setDownloadPopupShow, setDownloadPopupUrl } = useStore()
const { downloadInfo } = storeToRefs(useStore())
const gtag = useGtag()
const props = defineProps({
  link: String,
  platform: {
    type: String as PropType<DownloadPlatform>,
    default: 'all'
  },
  className: null as unknown as PropType<unknown>
})
const filename = computed(() => {
  const url = props.link
  return url?.substring(url?.lastIndexOf('/') + 1, url.lastIndexOf('.'))
})
const handlePopup = () => {
  gtag('event', 'download_button_click', { event_category: 'Download', event_label: true })
  if (downloadInfo.value.popup.status) {
    gtag('event', 'download_popup_show', { event_category: 'Download', event_label: true })
    setDownloadPopupShow(true)
    setDownloadPopupUrl(props.link || '')
  } else {
    gtag('event', 'download_client', { event_category: 'Download', event_label: true })
    props.link && downloadFile(props.link, filename.value)
  }
}
</script>
<style lang="scss" scoped>
.com-download-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 46px;
  background-color: #ffba00;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  transition: 0.3s;
}
.com-download-icon {
  margin-left: 8px;
  width: 19px;
  height: 16px;
  margin-top: -1px;
}
@media not all and (hover: none) {
  .com-download-btn:hover {
    background-color: #fff500;
  }
}
@media not all and (min-width: 1024px) {
  .com-download-desktop {
    display: none;
  }
  .com-download-mobile {
    display: flex;
  }
}
@media screen and (min-width: 1024px) {
  .com-download-desktop {
    display: flex;
  }
  .com-download-mobile {
    display: none;
  }
}
</style>
